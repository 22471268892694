import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import * as globals from '../constants/globals';

function NavLogoItem({ onMouseEnterHandler, visible }) {
  const router = useRouter();
  let destination;
  switch (router.pathname) {
    case '/dashboard':
      destination = globals.VIEW_SLUG_SHOOTER_ADMIN;
      break;
    case '/proposals':
      destination = globals.VIEW_SLUG_RFP_ADMIN;
      break;
    default:
      destination = '/';
  }
  return (
    <div
      className={classNames(
        'nav__item-container',
        'nav__item-container--logo',
        {
          visible,
        }
      )}
    >
      <Link href={destination}>
        <a>
          <div className="nav__item nav__item--logo">
            <div
              className={classNames('nav__logo-container', { visible })}
              onMouseEnter={onMouseEnterHandler}
            >
              <div className="nav__logo" />
            </div>
            <CSSTransition
              in={false}
              classNames="fade-in-out-500"
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <h1 className="nav__title">Häävalokuvaajat.fi</h1>
            </CSSTransition>
          </div>
        </a>
      </Link>
    </div>
  );
}

NavLogoItem.propTypes = {
  onMouseEnterHandler: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default NavLogoItem;
