import React from 'react';

function ModalMessagePrivacy() {
  return (
    <div>
      <p>
        Tämä on Yrityksen henkilötietolain (10 ja 24 §) ja EU:n yleisen
        tietosuoja-asetuksen mukainen rekisteri- ja tietosuojaseloste. Laadittu
        2.2.2020.
      </p>
      <h2 className="modal__heading">1. Rekisterinpitäjä</h2>
      <p>Ainavihanta Osakeyhtiö</p>
      <p>info@haavalokuvaajat.fi</p>
      <h2 className="modal__heading">2. Rekisterin nimi</h2>
      <p>Yrityksen asiakasrekisteri.</p>
      <h2 className="modal__heading">
        3. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus
      </h2>
      <p>
        EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen
        käsittelylle on asiakassuhteen ylläpito.
      </p>
      <p>
        Henkilötietojen käsittelyn tarkoitus on yhteydenpito asiakkaisiin,
        asiakassuhteen ylläpito, markkinointi tms.
      </p>
      <h2 className="modal__heading">4. Rekisterin tietosisältö</h2>
      <p>
        Rekisteriin tallennettavia tietoja ovat: henkilön nimi, yhteystiedot
        (puhelinnumero, sähköpostiosoite, osoite), www-sivustojen osoitteet,
        verkkoyhteyden IP-osoite, tunnukset/profiilit sosiaalisen median
        palveluissa, tiedot tilatuista palveluista ja niiden muutoksista,
        laskutustiedot, muut asiakassuhteeseen ja tilattuihin palveluihin
        liittyvät tiedot.
      </p>
      <h2 className="modal__heading">5. Säännönmukaiset tietolähteet</h2>
      <p>
        Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm.
        www-lomakkeilla lähetetyistä viesteistä, sähköpostitse, puhelimitse,
        sosiaalisen median palvelujen kautta, sopimuksista, asiakastapaamisista
        ja muista tilanteista, joissa asiakas luovuttaa tietojaan.
      </p>
      <h2 className="modal__heading">
        6. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai
        ETA:n ulkopuolelle
      </h2>
      <p>
        Tietoja ei luovuteta säännönmukaisesti muille tahoille. Tietoja voidaan
        julkaista siltä osin kuin niin on sovittu asiakkaan kanssa.
      </p>
      <h2 className="modal__heading">7. Rekisterin suojauksen periaatteet</h2>
      <p>
        Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien
        avulla käsiteltävät tiedot suojataan asianmukaisesti. Kun
        rekisteritietoja säilytetään Internet-palvelimilla, niiden laitteiston
        fyysisestä ja digitaalisesta tietoturvasta huolehditaan
        asiaankuuluvasti. Rekisterinpitäjä huolehtii siitä, että tallennettuja
        tietoja sekä palvelimien käyttöoikeuksia ja muita henkilötietojen
        turvallisuuden kannalta kriittisiä tietoja käsitellään
        luottamuksellisesti ja vain niiden työntekijöiden toimesta, joiden
        työnkuvaan se kuuluu.
      </p>
      <h2 className="modal__heading">
        8. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista
      </h2>
      <p>
        Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa
        rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen
        tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli
        henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin
        oikaisua, pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle.
        Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan
        henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n
        tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden
        kuluessa).
      </p>
      <h2 className="modal__heading">
        9. Muut henkilötietojen käsittelyyn liittyvät oikeudet
      </h2>
      <p>
        Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien
        henkilötietojen poistamiseen rekisteristä (“oikeus tulla unohdetuksi”).
        Niin ikään rekisteröidyillä on muut{' '}
        <a
          className="c10"
          href="http://eur-lex.europa.eu/legal-content/FI/TXT/?uri=CELEX%3A32016R0679#d1e2144-1-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet
        </a>{' '}
        kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa.
        Pyynnöt tulee lähettää kirjallisesti rekisterinpitäjälle.
        Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan
        henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n
        tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden
        kuluessa).
      </p>
    </div>
  );
}

export default ModalMessagePrivacy;
