import React from 'react';

function ModalMessageLinking() {
  return (
    <>
      <p>
        Voit halutessasi lisätä profiiliisi linkin sivustollesi / portfolioosi,
        jos vastaavasti lisäät paluulinkin Häävalokuvaajiin. Suosittelemme
        linkin lisäämistä, sillä se parantaa sekä sivustosi että
        Häävalokuvaajien hakukonenäkyvyyttä.
      </p>
      <p>
        Jos lisäät URL-osoitteita profiilisi teksteihin, niistä ei muodostu
        linkkejä. Hakukoneet eivät tällöin huomioi niitä samalla tavalla.
      </p>
      <h2 className="modal__heading">Paluulinkin sijainti</h2>
      <p>
        Paluulinkin on oltava siinä osoitteessa, jonne linkität profiilistasi.
        Jos esimerkiksi profiilisi on osoitteessa https://portfolioni.fi ja
        haluat linkittää sen profiiliisi, on paluulinkin myös oltava sivulla
        https://portfolioni.fi.
      </p>
      <h2 className="modal__heading">Paluulinkin muoto</h2>

      <p>
        Paluulinkin osoitteen on oltava kauttaviivoineen kaikkineen täsmälleen
        oikein:
      </p>
      <p>
        <em>https://www.häävalokuvaajat.fi</em>
      </p>
      <p>
        Itse linkin tekstillä ei ole merkitystä. Se voi olla esimerkiksi
        &quot;Häävalokuvaajat.fi&quot;.
      </p>

      <h2 className="modal__heading">Linkittävät kuvat</h2>

      <p>
        Voit käyttää linkkinä myös kuvaa. Alla on kolme vaihtoehtoa, joista voit
        valita taustallesi parhaiten soveltuvan. Jos sivustollesi ei ole
        mahdollista ladata SVG-kuvaa, voit käyttää myös PNG-versiota.
      </p>

      <p className="modal__img-title">Läpinäkyvä valkoisella tekstillä</p>
      <img
        alt="Logo valkoisella tekstillä ja läpinäkyvällä taustalla"
        className="modal__img"
        src="/static/img/haavalokuvaajat_trans_white.svg"
      />

      <ul className="modal__img-links">
        <li className="modal__img-link">
          <a target="_blank" href="/static/img/haavalokuvaajat_trans_white.svg">
            Lataa SVG
          </a>
        </li>
        <li className="modal__img-link">
          <a target="_blank" href="/static/img/haavalokuvaajat_trans_white.png">
            Lataa PNG
          </a>
        </li>
      </ul>

      <p className="modal__img-title">Läpinäkyvä mustalla tekstillä</p>
      <img
        alt="Logo mustalla tekstillä ja läpinäkyvällä taustalla"
        className="modal__img"
        src="/static/img/haavalokuvaajat_trans_black.svg"
      />

      <ul className="modal__img-links">
        <li className="modal__img-link">
          <a target="_blank" href="/static/img/haavalokuvaajat_trans_black.svg">
            Lataa SVG
          </a>
        </li>
        <li className="modal__img-link">
          <a target="_blank" href="/static/img/haavalokuvaajat_trans_black.png">
            Lataa PNG
          </a>
        </li>
      </ul>

      <p className="modal__img-title">Taustavärillinen versio</p>
      <img
        alt="Taustavärillinen logo"
        className="modal__img"
        src="/static/img/haavalokuvaajat_fill_white.svg"
      />

      <ul className="modal__img-links">
        <li className="modal__img-link">
          <a target="_blank" href="/static/img/haavalokuvaajat_fill_white.svg">
            Lataa SVG
          </a>
        </li>
        <li className="modal__img-link">
          <a target="_blank" href="/static/img/haavalokuvaajat_fill_white.png">
            Lataa PNG
          </a>
        </li>
      </ul>

      <h2 className="modal__heading">Paluulinkin tarkastaminen</h2>

      <p>
        Kun olet asenttanut linkin, pyrimme tarkastamaan sen noin vuorokauden
        kuluessa. Kun paluulinkki on vahvistettu, oma linkkisi näkyy
        profiilissasi.
      </p>

      <h2 className="modal__heading">Paluulinkin poistaminen</h2>

      <p>
        Jos poistat paluulinkin sivustoltasi, oma linkkisi poistuu näkyvistä
        profiilissasi.
      </p>

      <h2 className="modal__heading">Ongelmanratkonta</h2>

      <p>
        Jos linkkisi ei tarkastuksesta huolimatta vahvistu, varmista että linkki
        on näkyvissä, osoite on kirjoitettu oikein ja paluulinkki varmasti on
        samassa osoitteessa, eikä esimerkiksi uudelleenohjauksen takana.
      </p>
    </>
  );
}

export default ModalMessageLinking;
