import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalMessageConditions from './ModalMessageConditions';
import ModalMessageLinking from './ModalMessageLinking';
import ModalMessagePrivacy from './ModalMessagePrivacy';
import {
  MODAL_MESSAGE_LINK,
  MODAL_MESSAGE_PRIVACY,
  MODAL_MESSAGE_CONDITIONS,
} from '../constants/globals';

const heading = (message) => {
  switch (message) {
    case MODAL_MESSAGE_PRIVACY: {
      return 'Rekisteri- ja tietosuojaseloste';
    }
    case MODAL_MESSAGE_LINK: {
      return 'Linkittäminen';
    }
    case MODAL_MESSAGE_CONDITIONS: {
      return 'Käyttöehdot';
    }
    default:
      return false;
  }
};

const text = (message) => {
  switch (message) {
    case MODAL_MESSAGE_PRIVACY: {
      return <ModalMessagePrivacy />;
    }
    case MODAL_MESSAGE_LINK: {
      return <ModalMessageLinking />;
    }
    case MODAL_MESSAGE_CONDITIONS: {
      return <ModalMessageConditions />;
    }
    default:
      return false;
  }
};

class Modal extends Component {
  componentDidMount() {
    const body = document.querySelector('body');
    body.classList.add('noscroll');
  }

  componentWillUnmount() {
    const body = document.querySelector('body');
    body.classList.remove('noscroll');
  }

  render() {
    const { toggleModal, message } = this.props;
    return (
      <div className="modal">
        <div className="modal__container">
          <div className="modal__content">
            <div className="modal__title">{heading(message)}</div>
            <div className="modal__text-container">
              <div className="modal__text">{text(message)}</div>
            </div>
            <div
              role="button"
              onClick={(e) => toggleModal(e)}
              onKeyPress={(e) => toggleModal(e)}
              tabIndex={0}
              className="modal__close"
            >
              Sulje
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default Modal;
