import {
  PATHNAME_DASHBOARD,
  PATHNAME_PROPOSALS,
  // PATHNAME_LOGIN,
  SLUG_ADMIN,
  SLUG_RFPS,
  SLUG_SHOOTERINFO,
  SLUG_PROPOSALS,
  // VIEW_ADMIN,
  // TAB_ADMIN_ALL,
  // VIEW_SHOOTERINFO,
  VIEW_SLUG_ADMIN,
  // VIEW_SLUG_LOGIN,
  VIEW_SLUG_LOGOUT,
  // VIEW_SLUG_JOIN,
  VIEW_SLUG_SHOOTERINFO,
  VIEW_SLUG_RFPS,
  VIEW_SLUG_RFP_ADMIN,
} from './globals';

const navItems = [
  {
    icon: '',
    slug: SLUG_ADMIN,
    displayName: 'Admin',
    link: VIEW_SLUG_ADMIN,
    pathnames: [PATHNAME_DASHBOARD],
    adminOnly: true,
  },
  {
    icon: '',
    slug: SLUG_RFPS,
    displayName: 'Tarjouspyynnot',
    link: VIEW_SLUG_RFPS,
    section: 'tarjouspyynnot',
    pathnames: [PATHNAME_DASHBOARD],
  },
  {
    icon: '',
    displayName: 'Omat tiedot',
    slug: SLUG_SHOOTERINFO,
    link: VIEW_SLUG_SHOOTERINFO,
    section: 'omat-tiedot',
    pathnames: [PATHNAME_DASHBOARD],
  },
  {
    icon: '',
    displayName: 'Tarjoukset',
    slug: SLUG_PROPOSALS,
    link: VIEW_SLUG_RFP_ADMIN,
    pathnames: [PATHNAME_PROPOSALS],
  },
  /*
  {
    icon: '',
    displayName: 'Kirjaudu sisään',
    slug: 'login',
    link: VIEW_SLUG_LOGIN,
    pathnames: ['/', PATHNAME_LOGIN, PATHNAME_JOIN],
    loggedInStatus: false,
  },
  {
    icon: '',
    name: 'kuvaajaksi',
    displayName: 'Liity kuvaajaksi',
    slug: 'join',
    link: VIEW_SLUG_JOIN,
    pathnames: ['/', PATHNAME_LOGIN, PATHNAME_JOIN],
    loggedInStatus: false,
  },
  */
  {
    icon: '',
    displayName: 'Kirjaudu ulos',
    slug: 'logout',
    link: VIEW_SLUG_LOGOUT,
    pathnames: [PATHNAME_DASHBOARD, PATHNAME_PROPOSALS],
    loggedInStatus: true,
  },
];

const Pathname = (pathname) => {
  const items = navItems.filter((item) => item.pathnames.includes(pathname));
  return items;
};

export default Pathname;
