import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import * as globals from '../constants/globals';
import * as selectors from '../selectors/selectors';

function Badge({
  initializing,
  proposableRFPAmount,
  proposals,
  requests,
  shooterState,
  slug,
}) {
  const getBadge = (badgeType) => {
    if (badgeType === globals.SLUG_PROPOSALS) {
      return {
        info: `${Object.keys(proposals).length}`,
        meta: 'Tarjousten määrä',
      };
    }
    if (badgeType === globals.SLUG_RFPS) {
      return {
        info: `${proposableRFPAmount}`,
        meta: 'Tarjoustasi odottavat pyynnöt',
      };
    }
    if (badgeType === globals.SLUG_SHOOTERINFO) {
      return shooterState ? false : { info: '!', meta: 'Tietoja puuttuu' };
    }
    return { info: null, meta: null };
  };

  if (requests === null || proposals === null || !slug) {
    return false;
  }
  const { info, meta } = getBadge(slug, proposals, requests);
  if (!info) {
    return false;
  }
  return (
    <CSSTransition
      in={!!info && !initializing}
      classNames="fade-in-out-500"
      mountOnEnter
      unmountOnExit
      timeout={500}
    >
      <span className="badge" data-tip={meta}>
        {info}
      </span>
    </CSSTransition>
  );
}

Badge.propTypes = {
  initializing: PropTypes.bool.isRequired,
  proposableRFPAmount: PropTypes.number.isRequired,
  proposals: PropTypes.oneOfType([() => null, PropTypes.instanceOf(Array)]),
  requests: PropTypes.oneOfType([() => null, PropTypes.instanceOf(Array)]),
  shooterState: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
};

Badge.defaultProps = {
  proposals: null,
  requests: null,
};

const mapStateToProps = (state) => ({
  initializing: selectors.initializingView(state),
  proposableRFPAmount: selectors.proposableRFPAmount(state),
  proposals: selectors.proposals(state),
  requests: selectors.requests(state),
  shooterState: selectors.shooterState(state),
});

export default connect(mapStateToProps)(Badge);
