import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import NavItem from './NavItem';
import NavLogoItem from './NavLogoItem';
import {
  PATHNAME_DASHBOARD,
  PATHNAME_PROPOSALS,
  PATHNAME_HOME,
  PATHNAME_JOIN,
  VIEW_SLUG_RFP_ADMIN,
  VIEW_SLUG_SHOOTER_ADMIN,
} from '../constants/globals';
import getNavItems from '../constants/navItems';
import * as selectors from '../selectors/selectors';

function Nav() {
  const isLoggedIn = useSelector(selectors.isLoggedIn);
  const isAdmin = useSelector(selectors.isAdmin);
  const [visible, setVisible] = useState(false);
  const router = useRouter();
  const { pathname } = router;
  const pathSlug = pathname.substring(1);
  const slug = pathSlug.split('/')[0];
  const path = `/${slug}`;

  /**
   * Figures out if nav is visible at all times
   *
   * @returns {boolean}
   */
  const getAtAllTimesVisible = () =>
    path === PATHNAME_DASHBOARD || path === PATHNAME_PROPOSALS;

  useEffect(() => {
    setVisible(getAtAllTimesVisible());
  }, []);

  /**
   * Handles mouse leaving hover over nav
   *
   * @returns {void}
   */
  const onMouseEnterHandler = () => {
    if (!getAtAllTimesVisible()) {
      setVisible(true);
    }
  };

  /**
   * Handles mouse entering hover over nav
   *
   * @returns {void}
   */
  const onMouseLeaveHandler = () => {
    if (!getAtAllTimesVisible()) {
      setVisible(false);
    }
  };

  /**
   * Figures out if nav login is visible
   *
   * @returns {boolean}
   */
  const getNavLoginVisible = () =>
    pathname === PATHNAME_HOME || pathname === PATHNAME_JOIN;

  // TODO: Refactor this to use selectors
  const getNavItemActive = (item) => {
    if (item.slug === 'logout') return false;
    if (item.slug === 'join') return false;
    const {
      query: { section, proposalId },
    } = router;
    if (section !== undefined) {
      return section === item.section;
    }
    if (proposalId === undefined) {
      return true;
    }
    return false;
  };

  return (
    <>
      <nav
        className={classNames(
          'nav',
          { visible },
          `nav--${slug === '' ? 'home' : slug}`
        )}
        onMouseLeave={onMouseLeaveHandler}
      >
        <div className="nav__preload" />
        <div className={classNames('nav__title-container', { visible })}>
          <h1 className="nav__title">häävalokuvaajat.fi</h1>
        </div>
        <NavLogoItem
          path={path}
          onMouseEnterHandler={onMouseEnterHandler}
          visible={visible}
        />
        {getNavItems(path)
          .filter((item) => {
            if (item.loggedInStatus !== undefined) {
              return item.loggedInStatus === isLoggedIn;
            }
            return true;
          })
          .filter((item) => !item.adminOnly || (item.adminOnly && isAdmin))
          .map((item) => (
            <NavItem
              active={getNavItemActive(item)}
              key={item.displayName}
              link={item.link}
              name={item.name}
              displayName={item.displayName}
              params={item.params}
              slug={item.slug}
              visible={visible}
            />
          ))}
      </nav>
      {getNavLoginVisible() && (
        <div className="nav__login-container">
          <Link href={VIEW_SLUG_RFP_ADMIN}>
            <a className="nav__login-link nav__login-link--proposals">
              Selaa tarjouksia
            </a>
          </Link>
          <Link href={VIEW_SLUG_SHOOTER_ADMIN}>
            <a className="nav__login-link nav__login-link--login">
              Kirjaudu sisään
            </a>
          </Link>
        </div>
      )}
    </>
  );
}

export default Nav;
