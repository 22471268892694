import React, { Component } from 'react';

class Loader extends Component {
  componentDidMount() {
    const body = document.querySelector('body');
    body.classList.add('noscroll');
  }

  componentWillUnmount() {
    const body = document.querySelector('body');
    body.classList.remove('noscroll');
  }

  render() {
    return (
      <main className="loader">
        <div className="loader__container">
          <svg
            id="loader-logo"
            width="84px"
            height="114px"
            viewBox="0 0 84 114"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="logo">
                <polygon
                  id="Path"
                  fill="#FFFFFF"
                  points="76.7 68.1 71.7 70.5 71.7 45.9 76.7 48.8"
                />
                <rect
                  id="Rectangle"
                  fill="#FFFFFF"
                  x="15"
                  y="20.6"
                  width="56.1"
                  height="91.2"
                />
                <rect
                  id="Rectangle"
                  fill="#FFFFFF"
                  x="71.1"
                  y="97.1"
                  width="4.8"
                  height="9.4"
                />
                <rect
                  id="Rectangle"
                  fill="#FFFFFF"
                  x="16.4"
                  y="5.2"
                  width="53.7"
                  height="15.4"
                />
                <rect
                  id="Rectangle"
                  fill="#FFFFFF"
                  x="75.9"
                  y="43.7"
                  width="6.7"
                  height="27.8"
                />
                <rect
                  id="Rectangle"
                  fill="#FFFFFF"
                  x="19.7"
                  y="0"
                  width="47.3"
                  height="5.2"
                />
                <path
                  d="M17.3,25.7 L17.3,103.1 L17.9,103.3 C34,109.8 52,109.8 68.2,103.3 L68.8,103.1 L68.8,25.7 L68.2,25.5 C52,19 34,19 17.9,25.5 L17.3,25.7 Z M66.9,53.9 C65.8,52.5 64.4,51.5 62.8,51 L62.6,51 C62.1,50.8 61.6,50.5 61.4,50 C61.2,49.5 61.1,49 61.3,48.5 C62.6,44.7 62.6,40.6 61.4,36.8 C63.2,37.8 65.4,37.6 66.9,36.3 L66.9,53.9 Z M61.5,69.2 C65.7,79.4 60.8,91 50.6,95.2 C48.2,96.2 45.6,96.7 43.1,96.7 C42.6,96.7 42.1,96.7 41.6,96.6 C30.6,95.8 22.4,86.2 23.2,75.2 C23.4,73.1 23.8,71.1 24.6,69.1 C25.3,67.3 24.6,65.2 22.8,64.3 C20.7,63.2 19.4,61.1 19.4,58.8 C19.4,56 21.3,53.5 23.9,52.7 L24,52.7 C25,52.4 25.9,51.7 26.4,50.8 C26.9,49.9 27,48.8 26.6,47.9 C23.6,38.8 28.5,29 37.6,26 C39.4,25.4 41.2,25.1 43.1,25.1 L43.8,25.1 C53.3,25.5 60.8,33.5 60.4,43 C60.3,44.6 60.1,46.2 59.5,47.8 C59.2,48.8 59.3,49.8 59.7,50.7 C60.2,51.6 61,52.3 62,52.6 L62.1,52.6 C64.8,53.4 66.6,55.9 66.6,58.7 C66.6,61 65.3,63.2 63.2,64.2 C61.5,65.3 60.7,67.4 61.5,69.2 Z M65,35.2 C63.5,35.9 61.8,35.3 61.1,33.9 C60.7,33.1 60.7,32.1 61.1,31.3 L65,35.2 Z M62.4,29.9 C63.9,29.2 65.6,29.8 66.3,31.2 C66.7,32 66.7,33 66.3,33.8 L62.4,29.9 Z M19.2,27 C34.5,21 51.5,21 66.9,27 L66.9,28.9 C64.9,27.2 61.8,27.4 60.1,29.4 C59.5,30.1 59.1,30.9 59,31.8 C53,23 41.1,20.7 32.3,26.6 C25.2,31.4 22.1,40.3 24.8,48.5 C25,49 24.9,49.5 24.7,50 C24.4,50.5 24,50.8 23.5,51 L23.3,51 C21.7,51.5 20.2,52.6 19.2,53.9 L19.2,27 Z M19.2,63.8 C19.9,64.7 20.8,65.5 21.9,66.1 C22.8,66.5 23.2,67.6 22.8,68.5 C18.3,79.7 23.6,92.4 34.8,97 C36.9,97.9 39.1,98.4 41.4,98.5 C41.9,98.5 42.5,98.6 43.1,98.6 C55.2,98.6 64.9,88.8 64.9,76.8 C64.9,74 64.4,71.2 63.3,68.6 C62.9,67.7 63.3,66.6 64.2,66.2 C65.3,65.6 66.2,64.9 66.9,63.9 L66.9,101.9 C51.6,107.9 34.6,107.9 19.2,101.9 L19.2,63.8 Z"
                  id="Shape"
                  fill="#000000"
                  fillRule="nonzero"
                />
                <path
                  d="M43,52.2 C48.4,52.2 52.7,47.8 52.7,42.5 C52.7,37.1 48.3,32.8 43,32.8 C37.6,32.8 33.3,37.2 33.3,42.5 C33.3,47.8 37.6,52.2 43,52.2 Z M43,34.6 C47.3,34.6 50.8,38.1 50.8,42.4 C50.8,46.7 47.3,50.2 43,50.2 C38.7,50.2 35.2,46.7 35.2,42.4 C35.2,38.1 38.7,34.6 43,34.6 Z"
                  id="Shape"
                  fill="#EE5339"
                  fillRule="nonzero"
                />
                <path
                  d="M65.3,59 C65.3,56.3 63.1,54.2 60.5,54.2 C57.9,54.2 55.7,56.4 55.7,59 C55.7,61.6 57.9,63.8 60.5,63.8 C63.1,63.8 65.3,61.7 65.3,59 Z M60.4,62 C58.8,62 57.5,60.7 57.5,59 C57.5,57.4 58.8,56.1 60.5,56.1 C62.1,56.1 63.4,57.4 63.4,59 C63.4,60.6 62.1,62 60.4,62 C60.5,62 60.5,62 60.4,62 Z"
                  id="Shape"
                  fill="#000000"
                  fillRule="nonzero"
                />
                <path
                  d="M30.4,59 C30.4,56.3 28.2,54.2 25.6,54.2 C23,54.2 20.8,56.4 20.8,59 C20.8,61.6 23,63.8 25.6,63.8 C28.3,63.8 30.4,61.7 30.4,59 Z M25.6,62 C24,62 22.7,60.7 22.7,59.1 C22.7,57.5 24,56.2 25.6,56.2 C27.2,56.2 28.5,57.5 28.5,59.1 C28.6,60.6 27.3,62 25.6,62 C25.6,62 25.6,62 25.6,62 Z"
                  id="Shape"
                  fill="#000000"
                  fillRule="nonzero"
                />
                <path
                  d="M43,60.3 C33.9,60.3 26.6,67.6 26.6,76.7 C26.6,85.8 33.9,93.2 43,93.2 C52.1,93.2 59.4,85.9 59.4,76.8 C59.4,76.8 59.4,76.8 59.4,76.8 C59.4,67.7 52.1,60.3 43,60.3 Z M43,91.2 C35,91.2 28.5,84.7 28.5,76.7 C28.5,68.7 35,62.2 43,62.2 C51,62.2 57.5,68.7 57.5,76.7 C57.5,84.8 51,91.3 43,91.2 C43,91.2 43,91.2 43,91.2 Z"
                  id="Shape"
                  fill="#000000"
                  fillRule="nonzero"
                />
                <path
                  d="M43,67.5 C37.9,67.5 33.7,71.7 33.7,76.8 C33.7,81.9 37.9,86.1 43,86.1 C48.1,86.1 52.3,81.9 52.3,76.8 C52.3,71.6 48.1,67.5 43,67.5 Z M43,84.1 C38.9,84.1 35.6,80.8 35.6,76.7 C35.6,72.6 38.9,69.3 43,69.3 C47.1,69.3 50.4,72.6 50.4,76.7 C50.4,80.8 47.1,84.1 43,84.1 Z"
                  id="Shape"
                  fill="#EE5339"
                  fillRule="nonzero"
                />
                <path
                  d="M45,73.1 C45.5,73.1 45.9,72.7 45.9,72.1 C45.9,71.7 45.7,71.4 45.3,71.2 C44.5,70.9 43.7,70.7 42.9,70.7 C42.4,70.7 41.9,71.1 41.9,71.7 C41.9,72.2 42.3,72.6 42.9,72.6 C43.5,72.6 44,72.7 44.5,72.9 C44.7,73 44.9,73.1 45,73.1 Z"
                  id="Path"
                  fill="#EE5339"
                />
                <path
                  d="M46.9,73.4 C46.5,73.7 46.3,74.2 46.6,74.7 C47.7,76.7 47.1,79.2 45.1,80.3 C45,80.3 44.9,80.4 44.9,80.4 C44.4,80.6 44.2,81.2 44.4,81.6 C44.6,82 45.2,82.3 45.6,82.1 C45.6,82.1 45.7,82.1 45.7,82 C48.7,80.5 49.8,76.9 48.4,73.9 C48.3,73.8 48.3,73.7 48.2,73.6 C47.9,73.3 47.4,73.1 46.9,73.4 Z"
                  id="Path"
                  fill="#EE5339"
                />
                <path
                  d="M42.8,37.9 C43.4,37.9 44.1,38 44.7,38.3 C45.2,38.5 45.7,38.3 46,37.9 C46.2,37.4 46,36.9 45.6,36.6 C45.6,36.6 45.6,36.6 45.5,36.6 C44.7,36.2 43.8,36 42.9,36 C42.4,36 41.9,36.4 41.9,37 C41.9,37.6 42.2,37.9 42.8,37.9 Z"
                  id="Path"
                  fill="#EE5339"
                />
                <path
                  d="M44.8,46.7 C44.3,46.9 44.1,47.5 44.3,47.9 C44.5,48.3 45.1,48.6 45.5,48.4 C45.5,48.4 45.6,48.4 45.6,48.3 C48.8,46.7 50.1,42.7 48.5,39.5 C48.4,39.4 48.4,39.3 48.3,39.2 C48,38.7 47.4,38.6 47,38.9 C46.6,39.2 46.4,39.8 46.7,40.2 C48,42.4 47.3,45.3 45,46.6 C45,46.7 44.9,46.7 44.8,46.7 Z"
                  id="Path"
                  fill="#EE5339"
                />
                <path
                  d="M27,95.6 C25,95.6 23.3,97.2 23.3,99.3 C23.3,101.4 24.9,103 27,103 C29.1,103 30.7,101.4 30.7,99.3 C30.7,97.2 29,95.6 27,95.6 C27,95.6 27,95.6 27,95.6 Z M27,101 C26,101 25.2,100.2 25.2,99.2 C25.2,98.2 26,97.4 27,97.4 C28,97.4 28.8,98.2 28.8,99.2 C28.8,100.2 28,101 27,101 Z"
                  id="Shape"
                  fill="#000000"
                  fillRule="nonzero"
                />
                <path
                  d="M59,102.9 C61,102.9 62.7,101.3 62.7,99.2 C62.7,97.1 61,95.6 59,95.6 C57,95.6 55.3,97.2 55.3,99.3 C55.3,101.4 57,102.9 59,102.9 Z M59,97.5 C60,97.5 60.8,98.3 60.8,99.3 C60.8,100.3 60,101.1 59,101.1 C58,101.1 57.2,100.3 57.2,99.3 C57.2,98.3 58,97.5 59,97.5 Z"
                  id="Shape"
                  fill="#000000"
                  fillRule="nonzero"
                />
                <path
                  d="M81.2,42.6 L77.2,42.6 C75.9,42.6 74.8,43.7 74.8,45 L74.8,46 L71.7,44.5 L71.7,19.3 L71,19.1 L70.1,18.9 L70.1,4 L67.7,4 L67.7,0 L18.3,0 L18.3,4 L15.9,4 L15.9,19 L15,19.2 L14.3,19.4 L14.3,63.6 L5.7,98.7 C5.1,101.3 2.7,103.2 0,103.2 L0,105.1 C3.6,105.1 6.7,102.7 7.6,99.2 L14.4,71.6 L14.4,113.8 L71.8,113.8 L71.8,107 L77,107 L77,95.8 L71.8,95.8 L71.8,71 L74.9,69.5 L74.9,70.5 C74.9,71.8 76,72.9 77.3,72.9 L81.3,72.9 C82.6,72.9 83.7,71.8 83.7,70.5 L83.6,45 C83.6,43.7 82.5,42.6 81.2,42.6 Z M20.2,1.9 L65.9,1.9 L65.9,4 L20.2,4 L20.2,1.9 Z M17.8,5.9 L68.2,5.9 L68.2,18.5 C51.6,14.9 34.4,14.9 17.7,18.5 L17.8,5.9 Z M69.8,111.9 L16.2,111.9 L16.2,20.9 C33.8,16.8 52.2,16.8 69.8,20.9 L69.8,111.9 Z M75,97.7 L75,105.1 L71.7,105.1 L71.7,97.7 L75,97.7 Z M74.8,67.3 L71.7,68.8 L71.7,46.6 L74.8,48.1 L74.8,67.3 Z M81.7,70.5 C81.7,70.8 81.5,71 81.2,71 L77.2,71 C76.9,71 76.7,70.8 76.7,70.5 L76.7,70 L81.7,70 L81.7,70.5 Z M81.7,68.1 L76.7,68.1 L76.7,66.7 L81.7,66.7 L81.7,68.1 Z M81.7,64.8 L76.7,64.8 L76.7,62 L81.7,62 L81.7,64.8 Z M81.7,60.2 L76.7,60.2 L76.7,55.4 L81.7,55.4 L81.7,60.2 Z M81.7,53.4 L76.7,53.4 L76.7,50.6 L81.7,50.6 L81.7,53.4 Z M81.7,48.8 L76.7,48.8 L76.7,47.4 L81.7,47.4 L81.7,48.8 Z M81.7,45.5 L76.7,45.5 L76.7,45 C76.7,44.7 76.9,44.5 77.2,44.5 L81.2,44.5 C81.5,44.5 81.7,44.7 81.7,45 L81.7,45.5 Z"
                  id="Shape"
                  fill="#000000"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </svg>
        </div>
      </main>
    );
  }
}

export default Loader;
