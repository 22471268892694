import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import Modal from './Modal';
import * as globals from '../constants/globals';
import * as selectors from '../selectors/selectors';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(e) {
    e.preventDefault();
    const { modalVisible } = this.state;
    this.setState({
      modalVisible: !modalVisible,
    });
  }

  render() {
    const { modalVisible } = this.state;
    const { loggedInUserId } = this.props;
    const body = loggedInUserId
      ? `%0d%0a%0d%0a%0d%0atunniste: ${loggedInUserId}`
      : '';
    return (
      <footer className="footer">
        <div className="footer__content">
          <p className="footer__c">©</p>
          <p className="footer__text"> 2020 Ainavihanta</p>
          <p className="footer__text">|</p>
          <a
            className="footer__privacy"
            href="#tietosuoja"
            target="_blank"
            tabIndex={0}
            onClick={(e) => this.toggleModal(e)}
          >
            Tietosuojaseloste
          </a>
          <p className="footer__text">|</p>
          <a
            className="footer__privacy"
            href={`mailto:info@haavalokuvaajat.fi?subject=Palaute hääkuvaajat.fi sivustolta&body=${body}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Palaute
          </a>
        </div>
        <CSSTransition
          in={modalVisible}
          classNames="fade-in-out-500"
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <Modal
            toggleModal={this.toggleModal}
            message={globals.MODAL_MESSAGE_PRIVACY}
          />
        </CSSTransition>
      </footer>
    );
  }
}

Footer.defaultProps = {
  loggedInUserId: undefined,
};

Footer.propTypes = {
  loggedInUserId: PropTypes.oneOfType([() => undefined, PropTypes.string]),
};

const mapStateToProps = (state) => ({
  loggedInUserId: selectors.loggedInUserId(state),
});

export default connect(mapStateToProps)(Footer);
