import getConfig from 'next/config';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import LoadingBar from 'react-redux-loading-bar';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import * as selectors from '../selectors/selectors';

function CoreLayout({ children, initializingView }) {
  if (process.env.NEXT_PUBLIC_MAINTENANCE) {
    return <Loader />;
  }
  return (
    <>
      {initializingView && <Loader />}
      {!initializingView && <Nav />}
      <LoadingBar className="loading" />
      <ToastContainer
        className="toast"
        position="top-center"
        autoClose={4500}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
      />
      {children}
      {!initializingView && <Footer />}
    </>
  );
}

CoreLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  initializingView: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  initializingView: selectors.initializingView(state),
});

export default connect(mapStateToProps)(CoreLayout);
