import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import * as selectors from '../selectors/selectors';
import Badge from './Badge';

function NavItem({ active, displayName, link, slug, visible }) {
  const showBadge = useSelector(selectors.canShowBadge);
  return (
    <div
      className={classNames('nav__item-container', {
        active,
      })}
    >
      <CSSTransition
        in={visible}
        classNames="fade-in-out-500"
        mountOnEnter
        unmountOnExit
        timeout={500}
      >
        <Link href={link}>
          <a className="nav__item-link">
            <div
              className={classNames('nav__item', `nav__item--${slug}`, {
                active,
                visible,
              })}
            >
              <span
                className={classNames('nav__item-name', {
                  active,
                  visible,
                })}
              >
                {displayName}
              </span>
              {showBadge && <Badge slug={slug} />}
            </div>
          </a>
        </Link>
      </CSSTransition>
    </div>
  );
}

NavItem.propTypes = {
  active: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default NavItem;
