import React from 'react';

function ModalMessageConditions() {
  return (
    <div>
      <h2 className="modal__heading">1. Soveltaminen</h2>
      <p>
        Näitä ehtoja sovelletaan Ainavihanta Osakeyhtiön (jäljempänä
        “Palveluntarjoaja”) häävalokuvaajat.fi palveluun (jäljempänä “Palvelu”)
        valokuvaajille (jäljempänä “Kuvaaja”). Ehtoja sovelletaan myös Kuvaajan
        Palvelun kautta sopimaan kuvauspalveluun (jäljempänä “Kuvauspalvelu”),
        jonka asiakas on Palvelun käyttäjä (jäljempänä “Asiakas”). Kuvaaja
        hyväksyy nämä ehdot tekemällä Asiakkaalle tarjouksen.
      </p>
      <h2 className="modal__heading">2. Sopimuksen syntyminen</h2>
      <p>
        Sopimus Kuvaajan ja Palveluntarjoajan välillä syntyy, kun Kuvaaja on
        ilmoittanut hyväksyvänsä nämä ehdot ja jättänyt Asiakkaalle tarjouksen.
      </p>
      <p>
        Palveluntarjoaja voi kieltäytyä sopimuksesta tai lykätä tai keskeyttää
        toimituksensa, jos Kuvaajalla on erääntyneitä saatavia tai
        Palveluntarjoajalla on muutoin perusteltu syy epäillä, että Kuvaaja
        tulee rikkomaan sopimusta.
      </p>
      <p>Sopimuksen syntyminen ei sido Asiakasta.</p>
      <h2 className="modal__heading">
        3. Palvelun toimittaminen ja hyväksyminen
      </h2>

      <p>
        Palveluntarjoaja toimittaa palvelun parhaaksi katsomallaan tavalla
        sellaisena kuin se on palvelukuvauksessa kuvattu tai Kuvaajan kanssa
        erikseen sovittu. Palveluntarjoaja voi toimituksissaan käyttää
        alihankkijoita tai muita sopimuskumppaneita.
      </p>

      <p>
        Palveluntarjoajalla on oikeus tehdä palveluun teknisiä ja muita
        muutoksia, mikäli palvelun tarkoitus ei olennaisesti muutu.
        Palveluntarjoajalla on myös oikeus tehdä palveluun olennaisia muutoksia,
        mikäli hyöty Kuvaajalle on ilmeinen, kuten uuden teknologian
        käyttöönotto. Palveluntarjoajalla on myös oikeus tehdä muutoksia, mikäli
        lainsäädäntö muuttuu, viranomainen määrää tai jos muutoksen
        toteuttaminen on perusteltua myöhemmän vaaran tai vahingon
        välttämiseksi.
      </p>

      <p>
        Palveluntarjoaja tiedottaa kaikista olennaisista palvelun muutoksista
        ennakkoon. Jos tiedottaminen ennakkoon ei ole mahdollista, tiedottaa
        palveluntarjoaja muutoksesta heti kun se on mahdollista.
      </p>

      <p>
        Palvelun toimitus katsotaan hyväksytyksi, kun Kuvaaja niin ilmoittaa,
        tai kun Kuvaaja maksaa palvelusta. Palvelun toimitus katsotaan
        hyväksytyksi myös jos Kuvaaja ei ole kahden viikon kuluessa ilmoittanut
        mitään eikä esittänyt reklamaatiota. Kuvaajalla ei ole oikeutta jättää
        Palvelun toimitusta hyväksymättä ilman perusteltua syytä.
      </p>

      <h2 className="modal__heading">4. Palveluntarjoajan velvollisuudet</h2>

      <p>
        Palveluntarjoaja vastaa siitä, että tietoteknisten palvelujen ympäristö
        on asianmukaisesti suojattu, valvottu, ylläpidetty ja varmistettu.
      </p>

      <h2 className="modal__heading">5. Kuvaajan velvollisuudet</h2>

      <p>
        Kuvaaja sitoutuu toimittamaan Asiakkaalle tarjoamansa Kuvauspalvelun
        tarjouksessaan määrittelemällään tavalla sekä hinnalla, huolellisesti ja
        ammattitaitoa noudattaen.
      </p>

      <p>
        Jos Asiakas ottaa Kuvaajaan yhteyttä suoraan, hyväksymättä Kuvaajan
        tarjousta Palvelussa, Kuvaaja on velvoitettu neuvomaan Asiakasta
        hyväksymään Kuvaajan tarjous Palvelussa ennen oman Kuvauspalvelunsa
        toimitusta, tai tehtävä vahvistus itse. Kuvaajalla ei ole oikeutta
        toimittaa tarjoamaansa Kuvauspalvelua Asiakkaalle ennen kuin tarjous on
        hyväksytty Palvelussa.
      </p>

      <p>
        Kuvaaja on velvollinen perehtymään Palveluntarjoajan tiedotteisiin,
        jotka on toimitettu Kuvaajalle tämän ilmoittamaan yhteystietoon. Kuvaaja
        on velvollinen ilmoittamaan Palveluntarjoajalle muuttuneista
        yhteystiedoista.
      </p>

      <p>
        Kuvaaja sitoutuu noudattamaan tilaamiaan palveluja koskevia ohjeita ja
        käyttöehtoja. Jos Kuvaajan palveluiden käyttöön osallistuu useampi
        henkilö, vastaa Kuvaaja siitä, että kyseinen henkilökunta saa ne tiedot,
        jotka Kuvaajalla on.
      </p>

      <p>
        Kuvaaja vastaa käyttäjätunnustensa ja salasanojensa turvallisesta
        jakelusta ja säilyttämisestä, sekä riittävän vahvojen salasanojen
        käytöstä.
      </p>

      <p>
        Kuvaaja vastaa kaikesta sisällöstä omilla tietokoneillaan ja sitä kautta
        tuodusta sisällöstä Palveluntarjoajan palveluun, jotta sisältö tai sen
        käyttötapa ei riko tekijänoikeuksia, tietosuojaa tai muuta tiedon
        käsittelyyn tai levittämiseen liittyvää lakia.
      </p>

      <p>
        Kuvaaja sitoutuu käyttämään Palveluntarjoajan palveluita siten, ettei
        toiminta ole lakien, määräysten tai hyvien tapojen vastaista, eikä
        Kuvaajan toiminta tuota haittaa muille Kuvaajille tai Palveluntarjoajan
        liiketoiminnalle muutoinkaan. Kuvaaja on vahingonkorvausvelvollinen, jos
        Kuvaajasta johtuvasta syystä kolmas osapuoli vaatii Palveluntarjoajalta
        vahingonkorvausta perustellusti.
      </p>

      <h2 className="modal__heading">6. Tietoturva ja tietosuoja</h2>

      <p>
        Palveluntarjoaja vastaa palvelun tietoturvasta niiltä osin, jonka
        Palveluntarjoaja on toteuttanut ja saattanut Kuvaajan käytettäväksi
        palvelukuvausten mukaisesti. Palveluntarjoaja ei vastaa kolmansien
        osapuolien ohjelmistojen toiminnasta.
      </p>

      <p>
        Kuvaajalla ei ole oikeutta murtaa tai kiertää toimittajan ylläpitämän ja
        suojaaman tietojärjestelmän teknisiä suojauskeinoja.
      </p>

      <p>
        Palveluntarjoajan tarjoamaa palvelua voi käyttää henkilötietojen
        käsittelyyn ja säilyttämiseen EU:n yleisen tietosuoja-asetuksen
        mukaisesti. Tietosuojaan liittyvät asiat on kuvattu palvelun
        tietosuojaselosteessa.
      </p>

      <h2 className="modal__heading">7. Palvelun saatavuus</h2>

      <p>
        Palveluntarjoaja huolehtii, että tarjottu palvelu on saatavilla
        pääsääntöisesti ympäri vuorokauden kaikkina aikoina, pois lukien
        tilapäiset palvelukatkokset, jotka voivat johtua palveluiden ylläpito-,
        päivitys- tai korjaustoimenpiteistä tai muista näihin rinnastettavista
        tekijöistä.
      </p>

      <h2 className="modal__heading">8. Palvelun virhe</h2>

      <p>
        Palveluntarjoajan tavoitteena on tuottaa mahdollisimman laadukas ja
        virheetön palvelu. Kuvaajan tulee ilmoittaa havaitsemansa virheet
        kirjallisesti.
      </p>

      <h2 className="modal__heading">
        9. Palvelun keskeyttäminen tai sulkeminen
      </h2>

      <p>
        Palveluntarjoajalla on oikeus tilapäisesti keskeyttää palvelu, jos
        palvelun pitkäaikainen toimivuuden varmistaminen sitä edellyttää, kuten
        esimerkiksi poikkeuksellisten häiriöiden tai tietoturvaloukkauksen
        tutkimiseksi.
      </p>

      <p>
        Palveluntarjoajalla on oikeus keskeyttää tai päättää palvelu jos Kuvaaja
        rikkoo sopimusehtoja.
      </p>

      <h2 className="modal__heading">10. Maksut ja laskutus</h2>

      <p>
        Palvelun käyttö on Asiakkaalle maksutonta. Palvelun käyttö on myös
        Kuvaajalle maksutonta, pois lukien tilanne, jossa Asiakas hyväksyy
        Kuvaajan Palvelun kautta Asiakkaalle jättämän tarjouksen. Tällöin
        Kuvaaja maksaa Palveluntarjoajalle palvelusta hinnaston mukaisen maksun.
        Kuvaaja on velvollinen maksamaan mahdollisen arvonlisäveron sekä muut
        julkisoikeudelliset maksut.
      </p>

      <p>
        Kuvaaja ei ole oikeutettu toimittamaan Kuvauspalveluaan Asiakkaalle
        ennen kuin Kuvaaja on maksanut Palvelun palvelumaksun.
      </p>

      <p>
        Palvelun laskutus tapahtuu Asiakkaan hyväksyttyä Kuvaajan tarjouksen.
        Maksuehto on 14 päivää netto. Viivästyskorko on korkolain kohdan 4 a §:n
        mukainen.
      </p>

      <p>
        Maksettuja maksuja ei lähtökohtaisesti palauteta. Kuitenkin tilanteessa,
        jossa Asiakas ei vastaa Kuvaajan yhteydenottoihin useista yrityksistä
        huolimatta, tai muilla tavoin oleellisesti estää Kuvaajaa toimittamasta
        Kuvauspalveluaan Asiakkaalle, voidaan maksu palauttaa.
      </p>

      <p>
        Maksamattomista laskuista lähetetään yksi kirjallinen muistutus, josta
        ei peritä kuluja. Tämän jälkeen lasku siirtyy perintään, jonka
        aiheuttamista lisäkuluista vastaa Kuvaaja.
      </p>

      <p>
        Palveluntarjoajalla on oikeus muuttaa hinnastoaan, jolloin uusia hintoja
        sovelletaan Palveluun muutoshetkestä alkaen.
      </p>

      <p>
        Jos palveluun liittyvissä veroissa tai viranomaismaksuissa tapahtuu
        muutoksia, voi toimittaja muuttaa hinnastoaan vastaaviksi ilman
        ajallisia rajoituksia.
      </p>

      <h2 className="modal__heading">
        11. Vahingonkorvaus ja vastuurajoitukset
      </h2>

      <p>
        Osapuolet eivät vastaa toisille osapuolille aiheutuneesta välillisestä
        vahingosta, paitsi milloin vahinko on aiheutettu tahallisesti tai
        törkeällä huolimattomuudella.
      </p>

      <p>
        Palveluntarjoaja ei korvaa mitään välillisiä tai epäsuoria vahinkoja
        eikä Kuvaajan työajan tai ansion menetystä. Vastuurajoituslauseke ei
        kuitenkaan koske rikosoikeudellisia seuraamuksia tai hallinnollisia
        sakkoja.
      </p>

      <h2 className="modal__heading">12. Sopimuksen voimassaolo</h2>

      <p>
        Sopimuksen voimassaolo ja velvoitteet päättyvät, kun Kuvaaja on
        toimittanut Asiakkaan hyväksymän Kuvauspalvelun Asiakkaalle ja Kuvaaja
        on maksanut Palvelun palvelumaksun. Voimassaolo ja velvoitteet päättyvät
        myös, kun Asiakas on hyväksynyt jonkun muun kuin Kuvaajan tarjouksen,
        kuvaaja peruu tarjouksensa palvelussa tai kun Asiakkaan tarjouspyyntö on
        rauennut ilman hyväksyttyä tarjousta.
      </p>

      <h2 className="modal__heading">13. Ylivoimainen este</h2>

      <p>
        Palveluntarjoaja tai Kuvaaja ei ole velvollinen täyttämään sopimusta,
        jos sen täyttäminen estyy tai kohtuuttomasti hankaloituu ylivoimaisen
        esteen vuoksi (force majeure), kuten lakko tai työtaistelu,
        poikkeustila, sota, laaja häiriö tietoliikenne- tai sähköverkossa,
        luonnonmullistus, terrori-isku, viranomaisen pakkotoimi, muu oikeustoimi
        tai muut poikkeukselliset tilanteet.
      </p>

      <h2 className="modal__heading">
        14. Sovellettava laki ja erimielisyydet
      </h2>

      <p>
        Palveluntarjoajan ja Kuvaajan välisiin suhteisiin sovelletaan Suomen
        lakia, ei kuitenkaan kansainvälisyksityisoikeudellista lainvalintaa
        koskevia säännöksiä. Mahdolliset riidat tilaajan ja toimittajan välillä
        saatetaan ratkaistavaksi Helsingin käräjäoikeudessa.
      </p>
    </div>
  );
}

export default ModalMessageConditions;
